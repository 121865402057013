










































































































































































































































































































import Vue from 'vue';
import { message } from 'ant-design-vue';
import moment from 'moment';
import * as api from '@/api/tenantManage';
import { Empty } from 'ant-design-vue';
import {
  examine,
  hardwareapplyPage,
  hardwareapplySerials,
  infoDetail,
  singleexamine,
} from '@/api/lock';
import axios from 'axios';

const request = axios.create({
  baseURL: 'http://localhost:20002/controller/',
  timeout: 10000,
});
let timter = 0;
export default Vue.extend({
  name: 'tenantManageDetails',
  data() {
    return {
      newsns: [],
      opstatus: '1',
      optype: null,
      selectedData: [],
      title: '',
      showModal: false,
      basicInfo: {
        extend3: '',
        serial: null,
        sysUserBaseInfoDTO: {},
      },
      confirmLoading: false,
      tenantInfoSpinning: false,
      simpleImage: Empty['PRESENTED_IMAGE_SIMPLE'],
      previewVisible: false,
      previewImage: '',
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
        onChange: (page, pageSize) => {
          // console.log('page:', page);
          // console.log('pageSize:', pageSize);
          this['getSubAccountDataList'](page, pageSize, this.$route.query.id);
        },
        onShowSizeChange: (current, size) => {
          // console.log('page:', current);
          // console.log('pageSize:', size);
          this['getSubAccountDataList'](1, size, this.$route.query.id);
        },
      },
      tabKey: '0',
      fromData: {
        serial: null,
        status: '1',
        extend3: '',
        applyType: null,
        month: null,
        newserial: null,
      },
      renewalRecordColumns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
        },
        {
          title: '产品名称',
          dataIndex: 'pro',
          scopedSlots: { customRender: 'pro' },
        },
        {
          title: '续订期限',
          dataIndex: 'extend1',
          scopedSlots: { customRender: 'extend1' },
        },
        {
          title: '申请人',
          width: 100,
          dataIndex: 'callUserName',
        },
        {
          title: '联系方式',
          dataIndex: 'tel',
          scopedSlots: { customRender: 'tel' },
        },
        {
          title: '续订说明',
          dataIndex: 'description',
          width: 200,
          ellipsis: true,
        },
        {
          title: '提交时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '审核意见',
          dataIndex: 'extend3',
          width: 200,
          ellipsis: true,
        },
        {
          title: '处理人',
          width: 100,
          dataIndex: 'executeUserName',
        },
        {
          title: '处理时间',
          dataIndex: 'executeTime',
          scopedSlots: { customRender: 'executeTime' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      lossRecordColumns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
        },
        {
          title: '挂失加密锁设备号',
          dataIndex: 'serial',
        },
        {
          title: '挂失说明',
          dataIndex: 'description',
          width: 200,
          ellipsis: true,
        },
        {
          title: '申请人',
          width: 100,
          dataIndex: 'callUserName',
        },
        {
          title: '联系方式',
          dataIndex: 'tel',
        },
        {
          title: '申请时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '审核意见',
          dataIndex: 'extend3',
          width: 200,
          ellipsis: true,
        },
        {
          title: '处理人',
          width: 100,
          dataIndex: 'executeUserName',
        },
        {
          title: '处理时间',
          dataIndex: 'executeTime',
          scopedSlots: { customRender: 'executeTime' },
        },
        {
          title: '新加密锁设备号',
          dataIndex: 'extend1',
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
        },
      ],
      unbindRecordColumns: [
        {
          title: '序号',
          dataIndex: 'sortNo',
        },
        {
          title: '绑定时间',
          dataIndex: 'extend1',
        },
        {
          title: '绑定设备名称',
          dataIndex: 'name',
          width: 200,
        },
        {
          title: '设备序列号',
          dataIndex: 'nameId',
        },
        {
          title: '申请人',
          dataIndex: 'callUserName',
        },
        {
          title: '联系方式',
          dataindex: 'tel',
          scopedSlots: { customRender: 'tel' },
        },
        {
          title: '解绑说明',
          dataIndex: 'description',
          width: 200,
          ellipsis: true,
        },
        {
          title: '申请时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '审核意见',
          dataIndex: 'extend3',

          ellipsis: true,
        },
        {
          title: '处理人',

          dataIndex: 'executeUserName',
        },
        {
          title: '处理时间',
          dataIndex: 'executeTime',
          scopedSlots: { customRender: 'executeTime' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 80,
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center',
          width: 80,
        },
      ],
      subAccountData: [],
      appEmpowerData: [],
      tableData: [] as any,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.infoDetail();
    }
    timter = setInterval(() => {
      this.getLoaclFn();
    }, 5000);
  },
  destroyed() {
    if (timter) {
      clearInterval(timter);
    }
  },
  methods: {
    moment,

    getLoaclFn() {
      request
        .request({
          url: `/manager/getSn`,
          method: 'GET',
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.newsns = res.data.data;
          }
        });
    },
    submit() {
      let data = this.fromData;
      if (!data.status) {
        return this.$message.success('请选择审核状态');
      }
      if (data.status == '1') {
        if (data.applyType == 0 && !data.month) {
          return this.$message.success('请选择续订期限');
        }
        if (data.applyType == 2 && !data.newserial) {
          return this.$message.success('请选择新设备');
        }
      } else {
        data.newserial = null;
      }
      if (!data.extend3) {
        return this.$message.success('请填写审核意见');
      }
      this.confirmLoading = true;
      singleexamine(data).then((res) => {
        if (res.status === 200 && res.result) {
          this.$message.success('审核成功');
          this.hideModal();
          this.tabChange(this.tabKey);
        }
        this.confirmLoading = false;
      });
    },

    hideModal(isShowModal = true) {
      this.fromData = {
        serial: null,
        status: '1',
        extend3: '',
        applyType: null,
        month: null,
        newserial: null,
      };
      if (isShowModal) {
        this.showModal = false;
      }
    },
    reset() {
      this.fromData.serial = null;
      this.fromData.status = '1';
      this.fromData.extend3 = '';
      this.fromData.applyType = null;
      this.fromData.month = null;
      this.fromData.newserial = null;
    },
    showForm(type, record) {
      this.reset();
      this.fromData.serial = record.serial;
      this.fromData.applyType = type;
      this.optype = type;
      if (type === 0) {
        this.title = '续订';
        this.fromData.month = record.extend1;
      } else if (type === 1) {
        this.title = '解绑';
      } else if (type === 2) {
        this.title = '挂失';
      }
      //this.hideModal(false);
      this.showModal = true;
    },
    infoDetail() {
      infoDetail(this.$route.query.id).then((res) => {
        if (res.status === 200) {
          this.basicInfo = res.result;
          this.hardwareappluByserials(0);
        }
      });
    },
    hardwareappluByserials(type) {
      let arr = this.basicInfo.extend3 ? this.basicInfo.extend3.split(',') : [];
      if (this.basicInfo.serial) {
        arr.push(this.basicInfo.serial + '');
      }
      hardwareapplySerials(arr, type).then((res) => {
        if (res.status === 200 && res.result) {
          let arr: any = [];
          /*for (let i = 0; i < res.result.length; i++) {
            if (res.result[i].applyType == 2) {
              arr.push(res.result[i]);
            }
          }*/
          this.tableData = res.result;
          this.tableData.forEach((item, index) => {
            item.sortNo = index + 1;
            item.name = item.extend2 && item.extend2.split(',')[1];
            item.nameId = item.extend2 && item.extend2.split(',')[0];
          });
          console.log('this.tableData', this.tableData);
        }
      });
    },
    /**
     *
     * @param type 0 .续订记录
     *              1 , 解绑记录
     */
    hardwareapplyPage(type) {
      console.log(
        '🚀 ~ file: lockDetail.vue:565 ~ hardwareapplyPage ~ type:',
        type
      );
      let apiData = {
        serial: this.basicInfo.serial,
        //status: '0',
        applyType: type,
      };
      this.tableData = [];
      hardwareapplyPage(apiData).then((res) => {
        if (res.status === 200 && res.result) {
          this.tableData = res.result.list;
          this.tableData.forEach((item, index) => {
            item.sortNo = index + 1;

            item.name = item.extend2 && item.extend2.split(',')[1];
            item.nameId = item.extend2 && item.extend2.split(',')[0];
          });
        }
      });
    },
    tabChange(key) {
      this.tabKey = key;
      this.hardwareappluByserials(key);
    },
    handleCancel() {
      this.previewVisible = false;
    },
  },
});
